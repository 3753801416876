import Splide from '@splidejs/splide';
import 'fslightbox';
import SimpleBar from 'simplebar';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.ru';
document.addEventListener("DOMContentLoaded", function () {
	initQuantity(document.querySelectorAll(".quantity"))
	initDrops(document.querySelectorAll(".dropdown"))
	initSideMenu(document.querySelector(".side-menu"), document.querySelectorAll(".side-menu-toggler"), document.querySelector(".side-menu .close"))
	initFooterAccordeons(document.querySelectorAll("footer .col"))
	initMobileSearch(document.querySelector(".mobile-search"), document.querySelectorAll(".mobile-search-toggler"), document.querySelector(".mobile-search .close"))
	initInfoSlider(document.querySelector(".info-slider"))
	initAutoToggleNav(document.querySelector(".nav.auto-toggle"))
	initSelects(document.querySelectorAll(".custom-select select"))
	initBasketPopup(document.querySelector(".basket-popup-wrapper"), document.querySelectorAll("header .basket-popup-toggler"), document.querySelector(".basket-popup .close"))
	// paintStatusBar(document.querySelector('.catalog-header .status-bar'), calcPriceStatus(12))
	// paintStatusBar(document.querySelector('.basket-popup .status-bar'), calcPriceStatus(12))
	initInputsWithEyes(document.querySelectorAll('.custom-text-input.with-eye'))
	initPasswordsCouple(document.querySelector(".password-original"), document.querySelector(".password-repeat"))
	initDisableFieldsOnCheck(document.querySelector(".auth .col.entity input[type=checkbox]"), document.querySelectorAll(".auth .col.entity input[type=text]"), document.querySelectorAll(".auth .col.entity select"))
	initTelInputs(document.querySelectorAll("input[type='tel']"))
	refreshFsLightbox()
})
window.addEventListener("load", function () {
	initSpoilers(document.querySelectorAll('.spoiler'))
})

function initDisableFieldsOnCheck(check, ...fields) {
	if (check && fields.length) {
		fields.forEach(group => {
			group.forEach(node => {
				check.addEventListener('change', function () {
					node.disabled = !node.disabled;
				})
			})
		})
	}
}

function initSpoilers(spoilers) {
	if (spoilers.length) {
		spoilers.forEach((spoiler) => {
			let [content, toggler] = [spoiler.querySelector('.content'), spoiler.querySelector('.toggler')]
			if (spoiler.classList.contains('checkout-table')) {
				let table = spoiler.querySelector('table'),
					trs = table.querySelectorAll('tbody tr')
				if (trs.length <= 3) {
					toggler.remove()
				} else {
					let initialHeight = Array.from(trs)
						.slice(0, 3)
						.reduce(function (sum, current) {
								return sum += current.scrollHeight
							}, table.querySelector('thead')
							.scrollHeight)
					content.style.maxHeight = `${initialHeight}px`
					toggler.addEventListener("click", function () {
						vanillaSlideToggle(content, initialHeight)
						spoiler.classList.toggle('show')
					})
				}
			} else {
				toggler.addEventListener("click", function () {
					vanillaSlideToggle(content)
					spoiler.classList.toggle('show')
				})
			}
		})
	}
}

function initInputsWithEyes(inputLabels) {
	if (inputLabels.length) {
		inputLabels.forEach((inputLabel) => {
			let input = inputLabel.querySelector('input')
			let eye = inputLabel.querySelector('.icon-wrapper')
			eye.addEventListener("click", function () {
				if (input.type == "password") {
					input.type = "text"
					eye.classList.add('active')
				} else {
					input.type = "password"
					eye.classList.remove('active')
				}
			})
		})
	}
}

function calcPriceStatus(cartAmount, min = 12, opt1 = 32, opt2 = 108, opt3 = 256) {
	if (typeof cartSteps !== 'undefined' && cartSteps.isArray()) {
		[min, opt1, opt2, opt3] = cartSteps
	}

	function calcPercent(from, to) {
		return Math.floor((cartAmount - from) / (to - from) * 100)
	}
	if (cartAmount >= opt3) {
		return ["done", "done", "done", "done"]
	}
	if (cartAmount >= opt2) {
		return ["done", "done", 'done', calcPercent(opt2, opt3)]
	}
	if (cartAmount >= opt1) {
		return ["done", "done", calcPercent(opt1, opt2), undefined]
	}
	if (cartAmount >= min) {
		return ["done", calcPercent(min, opt1), undefined, undefined]
	}
	return [calcPercent(0, min), undefined, undefined, undefined]
}
/**
 * Красит блоки бара
 *
 * @param {HTMLElement} bar
 * @param {Array.<"done"|number|undefined>} states "done" красит в зеленый, number в прогресс, undefined в еще не задействованный никак
 */
function paintStatusBar(bar, states) {
	let items = bar.querySelectorAll('.item')
	items.forEach((item, index) => {
		item.classList.remove("done")
		item.classList.remove("progress")
		item.removeAttribute("style")
		if (typeof states[index] !== 'undefined') {
			if (states[index] == "done") {
				item.classList.add("done")
			} else {
				item.style.background = `linear-gradient(90deg, #A3AC9A80 0% ${states[index]}%, #EAECE5 ${states[index]}% 100%)`
				item.classList.add("progress")
			}
		}
	})
}

function initSelects(selects) {
	if (selects.length) {
		selects.forEach((select) => {
			select.addEventListener("click", function () {
				event.stopPropagation()
				this.classList.toggle("opened")
			})
			window.addEventListener("click", function () {
				if (select.classList.contains("opened")) {
					select.classList.remove("opened")
				}
			})
		})
	}
}

function initQuantity(items) {
	if (items.length) {
		items.forEach((numberInput) => {
			let input = numberInput.querySelector('input[type="number"]')
			numberInput.querySelectorAll(".icon")
				.forEach((btn) => {
					btn.addEventListener("click", function () {
						let lastValue = input.value
						input.value = +input.value + +this.dataset.value
						if (+input.value > input.max || +input.value < input.min) {
							input.value = lastValue
						}
					})
				})
			input.addEventListener("focusin", function () {
				input.select()
				input.addEventListener("input", function () {
					if (!input.checkValidity()) {
						input.value = 1
					}
				})
			})
		})
	} else {
		return false
	}
}

function initDrops(drops) {
	function dropDown(dropElem) {
		dropElem.classList.add("show")
	}

	function dropUp(dropElem) {
		dropElem.classList.remove("show")
	}

	function dropToggle(dropElem) {
		dropElem.classList.toggle("show")
	}
	if (drops.length) {
		drops.forEach((drop) => {
			drop.querySelector(".toggler")
				.addEventListener("click", function () {
					let shown = drop.classList.contains("show")
					drops.forEach((drop) => {
						dropUp(drop)
					})
					if (!shown) {
						dropDown(drop)
					}
				})
			window.addEventListener("click", function () {
				dropUp(drop)
			})
			drop.addEventListener("click", function () {
				event.stopPropagation()
			})
		})
	} else {
		return false
	}
}

function initBasketPopup(menu, togglers, close) {
	if (menu && togglers.length && close) {
		togglers.forEach((toggler) => {
			toggler.addEventListener("click", function () {
				event.preventDefault()
				menu.classList.add("show")
				toggleNoscrollBody()
			})
		})
		menu.querySelector('.basket-popup')
			.addEventListener("click", function () {
				event.stopPropagation()
			})
		menu.addEventListener("click", function () {
			menu.classList.remove("show")
			toggleNoscrollBody()
		})
		close.addEventListener("click", function () {
			menu.classList.remove("show")
			toggleNoscrollBody()
		})
	} else {
		return false
	}
}

function initSideMenu(menu, togglers, close) {
	if (menu && togglers.length && close) {
		togglers.forEach((toggler) => {
			toggler.addEventListener("click", function () {
				event.preventDefault()
				menu.classList.add("show")
				toggleNoscrollBody()
			})
		})
		menu.addEventListener("click", function () {
			menu.classList.remove("show")
			toggleNoscrollBody()
		})
		close.addEventListener("click", function () {
			menu.classList.remove("show")
			toggleNoscrollBody()
		})
		menu.querySelector(".menu")
			.addEventListener("click", function () {
				event.stopPropagation()
			})
	} else {
		return false
	}
}

function initMobileSearch(menu, togglers, close) {
	if (menu && togglers.length && close) {
		togglers.forEach((toggler) => {
			toggler.addEventListener("click", function () {
				menu.classList.toggle("show")
				toggleNoscrollBody()
				event.preventDefault()
			})
		})
		menu.addEventListener("click", function () {
			menu.classList.remove("show")
			toggleNoscrollBody()
		})
		close.addEventListener("click", function () {
			menu.classList.remove("show")
			toggleNoscrollBody()
		})
		menu.querySelector(".menu")
			.addEventListener("click", function () {
				event.stopPropagation()
			})
	} else {
		return false
	}
}

function initFooterAccordeons(accordeons) {
	if (accordeons.length) {
		accordeons.forEach((accordeon) => {
			let chevron = accordeon.querySelector(".icon-chevron-down")
			accordeon.querySelector("h5")
				.addEventListener("click", function () {
					vanillaSlideToggle(accordeon.querySelector(".col-content"))
					chevron.classList.toggle("icon-chevron-down")
					chevron.classList.toggle("icon-chevron-up")
				})
		})
	}
}

function vanillaSlideToggle(elem, initialHeight) {
	event.stopPropagation
	if (initialHeight) {
		if (elem.style.maxHeight != `${initialHeight}px`) {
			elem.style.maxHeight = `${initialHeight}px`
		} else {
			elem.style.maxHeight = `${elem.scrollHeight}px`
		}
	} else {
		if (elem.style.maxHeight) {
			elem.style.maxHeight = null
		} else {
			elem.style.maxHeight = `${elem.scrollHeight}px`
		}
	}
}

function initInfoSlider(slider) {
	if (slider) {
		new Splide(slider, {
				pagination: false,
			})
			.mount()
	}
}

function initAutoToggleNav(nav) {
	if (nav) {
		let sects = {}
		nav.querySelectorAll("a")
			.forEach((link) => {
				sects[link] = {
					node: link,
					offset: getCoords(document.querySelector(link.getAttribute("href")))
						.top,
				}
			})
		window.addEventListener("scroll", function () {
			for (const [key, value] of Object.entries(sects)) {
				if (window.scrollY + 100 >= value.offset) {
					var lastScroll = key
				}
			}
			if (lastScroll) {
				for (const [key, value] of Object.entries(sects)) {
					if (key == lastScroll) {
						value.node.classList.add("active")
					} else {
						value.node.classList.remove("active")
					}
				}
			}
		})
	}
}

function getCoords(elem) {
	var box = elem.getBoundingClientRect()
	return {
		top: box.top + pageYOffset,
		left: box.left + pageXOffset,
	}
}

function initElemValidaton(elem) {
	if (elem) {
		elem.addEventListener("input", function () {
			if (elem.pattern.test(this.value)) {
				this.setCustomValidity("");
			} else {
				this.setCustomValidity("Используйте требуемый формат");
			}
		});
	}
}

function initPasswordsCouple(original, repeat) {
	if (original && repeat) {
		repeat.addEventListener("input", function () {
			if (original.value === repeat.value) {
				repeat.setCustomValidity("");
			} else {
				repeat.setCustomValidity("Пароли не совпадают");
			}
		})
		original.addEventListener("input", function () {
			if (original.value === repeat.value) {
				repeat.setCustomValidity("");
			} else {
				repeat.setCustomValidity("Пароли не совпадают");
			}
		})
	}
}

function initTelInputs(inputs) {
	if (inputs.length) {
		inputs.forEach((input) => {
			new Cleave(input, {
				phone: true,
				phoneRegionCode: "ru",
				delimiter: "-",
				prefix: "+7",
				noImmediatePrefix: true
			});
		})
	}
}

function toggleNoscrollBody() {
	if (document.body.classList.contains('noscroll')) {
		document.querySelector('html')
			.classList.remove('noscroll')
		document.body.classList.remove('noscroll')
		document.body.style.paddingRight = ""
	} else {
		document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
		document.body.classList.add('noscroll')
		document.querySelector('html')
			.classList.add('noscroll')
	}
}